module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"linkImagesToOriginal":true,"quality":90},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JamesGu","short_name":"JamesGu","start_url":"/","background_color":"#fdf6e3","theme_color":"#eee8d5","display":"minimal-ui","icon":"src/images/jg-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"03be876db16c1fe1ca4359bc3e39d149"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
